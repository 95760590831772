import { Magic, RPCError, RPCErrorCode } from "magic-sdk";

const magic = new Magic(process.env.REACT_APP_MAGIC_PUBLISHABLE_KEY);

export const checkUser = async (setIsLoggedIn, setError) => {
  let isLoggedIn;
  try {
    // check if our user is coming from the login link in their email.
    if (window.location.href.includes("magic_credential") && !isLoggedIn) {
      // If they are coming from the login link, we want to use the
      // `loginWithCredential` method to check the user per documentation
      // https://magic.link/docs/api-reference/client-side-sdks/web#loginwithmagiclink
      // https://magic.link/docs/api-reference/client-side-sdks/web#loginwithcredential
      isLoggedIn = await magic.auth.loginWithCredential();
    } else {
      // If they aren't coming from the login link, we use the `isLoggedIn` method.
      isLoggedIn = await magic.user.isLoggedIn();
    }
    // isLoggedIn will be a token if the above was successful. We convert it to a boolean
    // and set the loggedin state.
    setIsLoggedIn(!!isLoggedIn);
  } catch (error) {
    catchErrors(error, setError, setIsLoggedIn);
    setIsLoggedIn(false);
  }
};

export const loginUser = async (email, setIsLoggedIn, setError) => {
  const redirectURI = `${window.location.origin}`;
  try {
    const token = await magic.auth.loginWithMagicLink({ email, redirectURI });
    if (!!token) {
      return setIsLoggedIn(true);
    }
  } catch (error) {
    catchErrors(error, setError, setIsLoggedIn);
  }
  return setIsLoggedIn(false);
};

export const logoutUser = async (setIsLoggedIn, setError) => {
  try {
    await magic.user.logout();
  } catch (error) {
    catchErrors(error, setError, setIsLoggedIn);
  }
  return setIsLoggedIn(false);
};

const catchErrors = async (error, setError, setIsLoggedIn) => {
  console.error(error);
  if (error instanceof RPCError) {
    catchRPCErrors(error, setError, setIsLoggedIn);
  } else {
    setError(
      "An error occured. Please try again or contact hello@advocacy-institute.org for help."
    );
  }
};

const catchRPCErrors = async (error, setError, setIsLoggedIn) => {
  switch (error.code) {
    case RPCErrorCode.MagicLinkFailedVerification:
      setError(
        "An internal error occured. Please try again or contact hello@advocacy-institute.org for help."
      );
      break;
    case RPCErrorCode.MagicLinkExpired:
      setError("The magic link has expired. Please try logging in again.");
      break;
    case RPCErrorCode.MagicLinkRateLimited:
      setError(
        "An internal error occured. Please try again or contact hello@advocacy-institute.org for help."
      );
      break;
    case RPCErrorCode.MagicLinkInvalidRedirectURL:
      setError(
        "The url is invalid. Please contact hello@advocacy-institute.org for help."
      );
      break;
    case RPCErrorCode.UserAlreadyLoggedIn:
      await logoutUser(setIsLoggedIn, setError);
      setError("An error occured. Please try again.");
      break;
    case RPCErrorCode.AccessDeniedToUser:
      setError(
        "You must be on the list of allowed users to access MyTools. Please contact hello@advocacy-institute.org for help."
      );
      break;
    default:
      setError(
        "An error occured. Please try again or contact hello@advocacy-institute.org for help."
      );
  }
};
