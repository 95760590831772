import { Modal, Button } from "react-bootstrap";

import "../styles/VideoPopup.css";

function VideoPopup(props) {
  return (
    <Modal
      className="video-popup-modal"
      show={props.show}
      onHide={props.close}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="video-popup-container">
          <iframe
            src={props.src}
            frameBorder="0"
            allowFullScreen
            webkitAllowFullscreen
            title="video popup container"
          ></iframe>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={props.close}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default VideoPopup;
