import React from "react";

import Login from "./Login";
import MyTools from "./MyTools";

function Home({ isLoggedIn, setIsLoggedIn, setError, error }) {
  return isLoggedIn ? (
    <MyTools setIsLoggedIn={setIsLoggedIn} setError={setError} />
  ) : (
    <Login setIsLoggedIn={setIsLoggedIn} setError={setError} error={error} />
  );
}

export default Home;
