import React from "react";

import { Modal, Button } from "react-bootstrap";

function UnsecureSitePopup({ showModal }) {
  return (
    <Modal show={showModal} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>Login Warning</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Sorry looks like you are at our unsecure site, click on the button to go
        to our secure site and login to My Tools.
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          href="https://mytools.advocacy-institute.org/"
        >
          Go to My Tools
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default UnsecureSitePopup;
