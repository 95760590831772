import { useEffect, useState } from "react";

import { Accordion } from "react-bootstrap";

import ListInfo from "./ListInfo";
import rbImg from "../assets/images/relationship-building.png";
import { fieldNames } from "../utilities/constants";
import { Tool, sortTools } from "../utilities/utils";

const { FORMAT, CATALOGUES } = fieldNames;

function RelBuilding(props) {
  //initializing the arrays that will receive data from api as empty arrays
  const [state, setState] = useState({
    audioData: [],
    pdfData: [],
    videoData: [],
    webData: [],
    listData: [],
  });

  // initializing items to be at 3
  const [items, setItems] = useState({
    audioItems: 3,
    pdfItems: 3,
    videoItems: 3,
    webItems: 3,
    listItems: 3,
  });

  useEffect(() => {
    let data = {
      audio: [],
      pdf: [],
      video: [],
      website: [],
      list: [],
    };

    props.records.forEach((record) => {
      const catalogues = record.get(CATALOGUES);
      if (catalogues && catalogues.includes("Relationship Building")) {
        let format = record.get(FORMAT).toLowerCase();
        data[format].push(record);
      }
    });

    const sortedData = sortTools(data, props.sortInfo);

    setState({
      audioData: sortedData.audio,
      pdfData: sortedData.pdf,
      videoData: sortedData.video,
      webData: sortedData.website,
      listData: sortedData.list,
    });
  }, [props.sortInfo, props.records]);

  //CardItems component will render with data based off the data we received from api
  //CardItems rendered will be based off its corresponding items number

  const audioList = state.audioData
    .slice(0, items.audioItems)
    .map((i) => Tool(i));

  const pdfList = state.pdfData.slice(0, items.pdfItems).map((i) => Tool(i));

  const webList = state.webData.slice(0, items.webItems).map((i) => Tool(i));

  const videoList = state.videoData
    .slice(0, items.videoItems)
    .map((i) => Tool(i));

  const listList = state.listData.slice(0, items.listItems).map((i) => Tool(i));

  return (
    <div id="rel-building-container">
      <div className="lcatt-details">
        <img
          className="lcatt-img"
          src={rbImg}
          alt="LCATT cycle with Relationship Building icon highlighted"
        />
        <h5 className="lcatt-text">
          Check out our
          <span>
            <a
              href="https://hi.advocacy-institute.org/lcatt/"
              target="_blank"
              rel="noreferrer"
            >
              Legislative Campaign Assessment and Tracking Tool
            </a>
          </span>
          for our bird’s-eye-diagram of the legislative advocacy process and a
          reminder of all the wins along the way.
        </h5>

        <h5 className="lcatt-text">
          Use these "Relationship Building" tools as you create your government
          engagement strategy and conduct a power analysis to identify your
          targets.
        </h5>
      </div>

      <Accordion>
        {/* will render each list with initially only 3 items, each 'see more' button will update each
      corresponding state by 3, allowing each list to show 3 more items. */}
        <Accordion.Item eventKey="0">
          <Accordion.Header>PDF</Accordion.Header>
          <Accordion.Body>
            {pdfList}
            {state.pdfData.length === null ||
            items.pdfItems >= state.pdfData.length ? null : (
              <button
                onClick={() =>
                  setItems({ ...items, pdfItems: items.pdfItems + 3 })
                }
              >
                see more
              </button>
            )}
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>WEB</Accordion.Header>
          <Accordion.Body>
            {webList}
            {state.webData.length === null ||
            items.webItems >= state.webData.length ? null : (
              <button
                onClick={() =>
                  setItems({ ...items, webItems: items.webItems + 3 })
                }
              >
                see more
              </button>
            )}
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>VIDEO</Accordion.Header>
          <Accordion.Body>
            {videoList}
            {state.videoData.length === null ||
            items.videoItems >= state.videoData.length ? null : (
              <button
                onClick={() =>
                  setItems({ ...items, videoItems: items.videoItems + 3 })
                }
              >
                see more
              </button>
            )}
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header>AUDIO</Accordion.Header>
          <Accordion.Body>
            {audioList}
            {state.audioData.length === null ||
            items.audioItems >= state.audioData.length ? null : (
              <button
                onClick={() =>
                  setItems({ ...items, audioItems: items.audioItems + 3 })
                }
              >
                see more
              </button>
            )}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>LIST</Accordion.Header>
          <Accordion.Body>
            <ListInfo />
            {listList}
            {state.listData.length === null ||
            items.listItems >= state.listData.length ? null : (
              <button
                onClick={() =>
                  setItems({ ...items, listItems: items.listItems + 3 })
                }
              >
                see more
              </button>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

export default RelBuilding;
