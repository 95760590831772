import React, { useState } from "react";

import { Form, FormControl } from "react-bootstrap";

import UnsecureSitePopup from "../components/UnsecureSitePopup";
import "../styles/Login.css";
import { loginUser } from "../utilities/magic";

function Login({ setIsLoggedIn, setError, error }) {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!email) {
      setLoading(false);
      return;
    }
    try {
      await loginUser(email, setIsLoggedIn, setError);
      setLoading(false);
    } catch (error) {
      setError(
        "Login failed. Please contact hello@advocacy-institute.org for help."
      );
      setLoading(false);
      console.error(error);
    }
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <div id="login-container">
      <div className="login">
        <h1>LOGIN</h1>
        <UnsecureSitePopup
          showModal={
            document.location.protocol !== "https:" &&
            process.env.REACT_APP_ENVIRONMENT !== "development"
          }
        />
        <h4>
          If you're a member, just type in your email. We'll send you a secure
          one-time link to access your tools.
        </h4>
        <Form onSubmit={handleSubmit}>
          <FormControl
            type="email"
            name="email"
            id="email"
            value={email}
            onChange={handleChange}
            placeholder="Email Address"
          />
          <button className="login-btn">
            {loading ? "Loading..." : "SUBMIT"}
          </button>
          {error ? <h6>{error}</h6> : ""}
          <h4 className="membership-info">
            Not a member yet? Contact us at:
            <span>
              {" "}
              <a href="mailto:membership@advocacy.institute.org">
                membership@advocacy.institute.org{" "}
              </a>
            </span>
          </h4>
        </Form>
      </div>
    </div>
  );
}

export default Login;
