import { useEffect, useState } from "react";

import { Accordion } from "react-bootstrap";

import { fieldNames } from "../utilities/constants";
import { Tool, sortTools } from "../utilities/utils";

const { CATALOGUES } = fieldNames;

function ToolsByRegion(props) {
  //initializing the arrays that will receive data from api as empty arrays
  const [state, setState] = useState({
    hudsonValleyData: [],
    longIslandData: [],
    nycData: [],
    nysData: [],
    wnyData: [],
    fingerLakesData: [],
  });

  // initializing items to be at 3
  const [items, setItems] = useState({
    hudsonValleyItems: 3,
    longIslandItems: 3,
    nycItems: 3,
    nysItems: 3,
    wnyItems: 3,
    fingerLakesItems: 3,
  });

  useEffect(() => {
    let data = {
      newyorkstate: [],
      hudsonvalley: [],
      longisland: [],
      newyorkcity: [],
      westernnewyork: [],
      fingerlakes: [],
    };

    props.records.forEach((record) => {
      const regions = [
        "New York State",
        "Hudson Valley",
        "Long Island",
        "New York City",
        "Western New York",
        "Finger Lakes",
      ];

      const catalogues = record.get(CATALOGUES);
      if (catalogues) {
        const foundRegion = catalogues.find((catalogue) => {
          return regions.includes(catalogue);
        });
        if (foundRegion) {
          const regionStr = foundRegion.toLowerCase().split(" ").join("");
          data[regionStr].push(record);
        }
      }
    });

    const sortedData = sortTools(data, props.sortInfo);

    setState({
      hudsonValleyData: sortedData.hudsonvalley,
      longIslandData: sortedData.longisland,
      nycData: sortedData.newyorkcity,
      nysData: sortedData.newyorkstate,
      wnyData: sortedData.westernnewyork,
      fingerLakesData: sortedData.fingerlakes,
    });
  }, [props.sortInfo, props.records]);

  //CardItems component will render with data based off the data we received from api
  //CardItems rendered will be based off its corresponding items number

  const hudsonValleyList = state.hudsonValleyData
    .slice(0, items.hudsonValleyItems)
    .map((i) => Tool(i));

  const longIslandList = state.longIslandData
    .slice(0, items.longIslandItems)
    .map((i) => Tool(i));

  const nycList = state.nycData.slice(0, items.nycItems).map((i) => Tool(i));

  const nysList = state.nysData.slice(0, items.nysItems).map((i) => Tool(i));

  const wnyList = state.wnyData.slice(0, items.wnyItems).map((i) => Tool(i));

  const fingerLakesList = state.fingerLakesData
    .slice(0, items.fingerLakesItems)
    .map((i) => Tool(i));

  return (
    <div id="tools-by-region-container">
      <Accordion>
        <Accordion.Item eventKey="0">
          {/* will render each list with initially only 3 items, each 'see more' button will update each
      corresponding state by 3, allowing each list to show 3 more items. */}
          <Accordion.Header>NEW YORK STATE</Accordion.Header>
          <Accordion.Body>
            {nysList}
            {state.nysData.length === null ||
            items.nysItems >= state.nysData.length ? null : (
              <button
                onClick={() =>
                  setItems({ ...items, nysItems: items.nysItems + 3 })
                }
              >
                see more
              </button>
            )}
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>FINGER LAKES</Accordion.Header>
          <Accordion.Body>
            {fingerLakesList}
            {state.fingerLakesData.length === null ||
            items.fingerLakesItems >= state.fingerLakesData.length ? null : (
              <button
                onClick={() =>
                  setItems({
                    ...items,
                    fingerLakesItems: items.fingerLakesItems + 3,
                  })
                }
              >
                see more
              </button>
            )}
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>HUDSON VALLEY</Accordion.Header>
          <Accordion.Body>
            {hudsonValleyList}
            {state.hudsonValleyData.length === null ||
            items.hudsonValleyItems >= state.hudsonValleyData.length ? null : (
              <button
                onClick={() =>
                  setItems({
                    ...items,
                    hudsonValleyItems: items.hudsonValleyItems + 3,
                  })
                }
              >
                see more
              </button>
            )}
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header>LONG ISLAND</Accordion.Header>
          <Accordion.Body>
            {longIslandList}
            {state.longIslandData.length === null ||
            items.longIslandItems >= state.longIslandData.length ? null : (
              <button
                onClick={() =>
                  setItems({
                    ...items,
                    longIslandItems: items.longIslandItems + 3,
                  })
                }
              >
                see more
              </button>
            )}
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="4">
          <Accordion.Header>NEW YORK CITY</Accordion.Header>
          <Accordion.Body>
            {nycList}
            {state.nycData.length === null ||
            items.nycItems >= state.nycData.length ? null : (
              <button
                onClick={() =>
                  setItems({ ...items, nycItems: items.nycItems + 3 })
                }
              >
                see more
              </button>
            )}
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="5">
          <Accordion.Header>WESTERN NEW YORK</Accordion.Header>
          <Accordion.Body>
            {wnyList}
            {state.wnyData.length === null ||
            items.wnyItems >= state.wnyData.length ? null : (
              <button
                onClick={() =>
                  setItems({ ...items, wnyItems: items.wnyItems + 3 })
                }
              >
                see more
              </button>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

export default ToolsByRegion;
