import React, { useState, useEffect } from "react";

import { Routes, Route } from "react-router-dom";

import "./App.css";
import headerImage from "./assets/images/Logo-text.png";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./pages/Home";
import Loading from "./pages/Loading";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import { checkUser } from "./utilities/magic";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const validateUser = async () => {
    setLoading(true);
    try {
      await checkUser(setIsLoggedIn, setError);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    validateUser();
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <div>
      <Header headerImage={headerImage} logoText="My Tools" />
      <Routes>
        <Route
          exact
          path="/"
          element={
            <Home
              isLoggedIn={isLoggedIn}
              setIsLoggedIn={setIsLoggedIn}
              setError={setError}
              error={error}
            />
          }
        />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
      </Routes>
      <Footer />
    </div>
  );
};
export default App;
