import React from "react";

import "../styles/Policies.css";

function TermsOfService() {
  return (
    <div className="policy-container">
      <h3>Terms of Service</h3>
      <p>Last Updated: 8/23/2023</p>
      <p>
        These Terms of Use, together with Advocacy Institute’s Privacy Policy
        (the “Privacy Policy”), set forth the terms and conditions (“Terms”)
        that apply to the access and use by you, as an individual, and by the
        entity, if any, which you represent (as applicable, “You”), of the
        Advocacy Institute website, located at{" "}
        <a href="https://hi.advocacy-institute.org/">
          https://hi.advocacy-institute.org/
        </a>{" "}
        (together, the “Website”) and constitutes an agreement by and between
        You and Advocacy Institute with respect to use of the Website and
        related web-based sites or platforms provided to You including, without
        limitation, My Tools and Interactive Snapshots (collectively, including
        without limitation any content hosted by Advocacy Institute thereon or
        provided by Advocacy Institute in connection therewith, the “Site”). In
        addition, these Terms apply to, and govern the provision and receipt of,
        services provided to You by Advocacy Institute which may include,
        without limitation, consultation and information regarding legislative
        and budget advocacy (the “Membership Services”), except to the extent
        that there is otherwise a superseding written agreement in effect (a
        “Written Agreement”) between You and Advocacy Institute with respect to
        the Membership Services. Notwithstanding to the extent that a Written
        Agreement so supersedes, the Privacy Policy continues to apply.
        “Advocacy Institute” includes Advocacy Institute, Inc., a New York
        not-for-profit corporation, and its officers, directors, employees,
        consultants, affiliates, subsidiaries, and agents. By using or accessing
        the Site and the Membership Services You agree to these Terms, as
        updated from time to time in accordance with Section 8 below. Because
        Advocacy Institute provides a wide range of services, we may at times
        ask You to review and accept supplemental terms that apply to Your
        interaction with a specific product or service.
        <span className="text-black">
          These Terms state that any disputes between You and Advocacy Institute
          must be resolved in arbitration
        </span>
      </p>
      <h3>Account Security</h3>
      <p>
        To use the Site or the Membership Services, You must (i) be at least
        eighteen (18) years of age; (ii) have not previously been suspended or
        removed from the Site or the Membership Services; and (iii) as
        applicable, register for and use the Site, and also use the Site in
        compliance with any and all applicable laws and regulations.
      </p>
      <h3>Account Registration</h3>
      <p>
        To access some features of the Site or the Membership Services, You may
        be required to register for an account. When you register for an
        account, we may ask You to give us certain identifying information about
        Yourself, including but not limited to Your email address and other
        contact information, and to create a user name and password
        (“Registration Information”). When registering for and maintaining an
        account, You agree to provide true, accurate, current, and complete
        information about Yourself. You also agree not to impersonate anyone,
        misrepresent any affiliation with anyone else, use false information, or
        otherwise conceal Your identity from Advocacy Institute for any purpose.
        You are solely responsible for maintaining the confidentiality and
        security of Your password and other Registration Information. For your
        protection and the protection of other users, we ask You not to share
        your Registration Information with anyone else. If you do share this
        information with anyone, we will consider their activities to have been
        authorized by You. If you have reason to believe that Your account is no
        longer secure, You must immediately notify us at
        hello@advocacy-institute.org.
      </p>
      <h3>Prohibited Conduct</h3>
      <p>You agree not to:</p>
      <ol type="A">
        <li>
          Use the Site or the Membership Services for any illegal purpose, or in
          violation of any local, state, national, or international law;
        </li>
        <li>
          Violate or encourage others to violate the rights of third parties,
          including intellectual property rights;
        </li>
        <li>
          Post, upload, or distribute any content that is unlawful, defamatory,
          libelous, inaccurate, or that Advocacy Institute deems to be
          objectionable, profane, indecent, pornographic, harassing,
          threatening, hateful, or otherwise inappropriate;
        </li>
        <li>
          Interfere in any way with security-related features of the Site;
        </li>
        <li>
          Interfere with the operation or any user’s enjoyment of the Site,
          including by uploading or otherwise disseminating viruses, adware,
          spyware, worms, or other malicious code, making unsolicited offers or
          advertisements to other users, or attempting to collect personal
          information about users or third parties without their consent;
        </li>
        <li>
          Access, monitor or copy any content or information of the Site using
          any robot, spider, scraper, or other automated means or any manual
          process for any purpose without Advocacy Institute’s express written
          permission;
        </li>
        <li>
          Perform any fraudulent activity, including impersonating any person or
          entity, claiming false affiliations, accessing the accounts of other
          users without permission, or falsifying your identity or any
          information about You, including age or date of birth; or
        </li>
        <li>Sell or otherwise transfer the access granted herein.</li>
      </ol>
      <h3>Third Party Content</h3>
      <p>
        The Site and the Membership Services may contain information about and
        links to third party websites and services. Advocacy Institute provides
        such information and links as a convenience, and does not control or
        endorse these websites and services. You acknowledge and agree that
        Advocacy Institute has not reviewed the content, advertising, products,
        services, or other materials that appear on such third party websites or
        services, and is not responsible for the legality, accuracy, or
        appropriateness of any such content. Advocacy Institute shall not be
        responsible or liable, directly or indirectly, for any damage or loss
        caused or alleged to be caused by or in connection with the use of any
        such third party websites or services.
      </p>
      <h3>Intellectual Property</h3>
      <p>
        You acknowledge and agree that you relinquish all ownership rights in
        any ideas or suggestions that you submit to Advocacy Institute through
        the Site or in connection with the Membership Services. The Site and its
        entire contents, features and functionalities, including the Provided
        Materials (defined below), are owned by Advocacy Institute protected by
        applicable copyright and other intellectual property laws. All
        trademarks and service marks on the Site belong to Advocacy Institute,
        except third-party trademarks or service marks, which are the property
        of their respective owners. Advocacy Institute may affirmatively provide
        You, through the Site or the Membership Services, with templates,
        flowcharts, guides, guidelines, spreadsheets or manuals to assist You in
        your advocacy efforts or for You to otherwise receive the benefit of the
        Membership Services (the “Provided Materials”). Advocacy Institute
        hereby grants You a right and license to use the Provided Materials to
        assist You in Your advocacy efforts and to receive the benefit of the
        Membership Services, provided however that, without the prior written
        consent of Advocacy Institute, You may not distribute the Provided
        Materials to unaffiliated third parties or use the Provided Materials to
        provide a competitive platform or service to Advocacy Institute.
      </p>
      <h3>User Content</h3>
      <p>
        You represent, warrant and covenant that You own or otherwise have the
        right to use any content You post to the Site or provide to Advocacy
        Institute in connection with the Membership Services. If You believe
        that Your content has been used in a way that constitutes copyright
        infringement, You may contact our Designated Agent:
      </p>
      <p className="text-black">
        cori schmanke parrish <br />
        Advocacy Institute <br />
        224 W. 35th St. Suite 500 #23 <br />
        New York, NY 10001 <br />
        (917) 398-3820 <br />
        hello@advocacy-institute.org <br />
      </p>
      <p>
        You must provide the following information: an electronic or physical
        signature of the copyright owner or the person authorized to act on
        behalf of the copyright owner; a description of the copyrighted work
        that You claim has been infringed; a description of where the material
        that You claim is infringing is located; Your address, telephone number,
        and email address; a written statement that You have a good faith belief
        that the disputed use is not authorized by the copyright owner, its
        agent, or the law; a statement by You, made under penalty of perjury,
        that the above information in your notice is accurate and that You are
        the copyright owner or authorized to act on the copyright owner’s
        behalf. Advocacy Institute has adopted and implements a policy that
        provides for termination in appropriate circumstances of accounts of
        users who repeatedly infringe copyright or other intellectual property
        rights of Advocacy Institute or others.
      </p>
      <h3>Indemnification</h3>
      <p>
        You agree that You will be personally responsible for Your use of the
        Site and the Membership Services, and You agree to defend, indemnify,
        and hold harmless Advocacy Institute from and against any and all
        claims, liabilities, damages, losses, and expenses (including attorneys’
        and accounting fees and costs), arising out of or in any way connected
        with (i) Your access to, use of, or alleged use of the Site or
        Membership Services; (ii) Your violation of the Terms or any applicable
        law or regulation; (iii) Your violation of any third party right,
        including without limitation any intellectual property right, publicity,
        confidentiality, property, or privacy right; or (iv) any disputes or
        issues between You and any third party. Advocacy Institute has
        reasonable approval rights over any settlement. Advocacy Institute
        reserves the right, at our own expense, to assume the exclusive defense
        and control of any matter otherwise subject to indemnification by You,
        and in such case, You agree to cooperate with our defense of such claim.
      </p>
      <h3>Termination</h3>
      <p>
        If You violate these Terms, Your permission to use the Site and the
        Membership Services will automatically terminate. In addition, Advocacy
        Institute, in its sole discretion, may suspend or terminate Your user
        account and/or suspend or terminate some or all of Your access to the
        Site or Membership Services at any time, with or without notice to You.
        You may terminate your account at any time by contacting Advocacy
        Institute at hello@advocacy-institute.org. After Your account is
        terminated, information and content previously provided by You will no
        longer be accessible through your account, but Advocacy Institute may
        continue to store such information and content, and it may also be
        stored by third parties to whom it has been transferred through Your use
        of the Site or the Membership Services.
      </p>
      <h3>Modification of the Terms</h3>
      <p>
        Advocacy Institute reserves the right at any time to modify these Terms
        and to impose new or additional terms or conditions on Your use of the
        Site or the Membership Services. Such modifications and additional terms
        and conditions will be effective immediately upon notice and
        incorporated into these Terms. We will make reasonable efforts to notify
        You of any material changes to the Terms, including, but not limited to,
        by posting a notice to our website or by sending an email to any address
        You may have provided to us. Your continued use of the Site or the
        Membership Services following notice will be deemed acceptance of any
        modifications to the Terms.
      </p>
      <h3>Disclaimers of Warranties</h3>
      <p>
        THE SITE AND THE MEMBERSHIP SERVICES ARE PROVIDED “AS IS” AND ON AN “AS
        AVAILABLE” BASIS, WITHOUT WARRANTY OR CONDITION OF ANY KIND, EITHER
        EXPRESS OR IMPLIED. ALTHOUGH ADVOCACY INSTITUTE SEEKS TO MAINTAIN SAFE,
        SECURE, ACCURATE, AND WELL-FUNCTIONING SERVICES, WE CANNOT GUARANTEE THE
        CONTINUOUS OPERATION OF OR ACCESS TO OUR SITE, AND THERE MAY AT TIMES BE
        INADVERTENT TECHNICAL OR FACTUAL ERRORS OR INACCURACIES. ADVOCACY
        INSTITUTE SPECIFICALLY (BUT WITHOUT LIMITATION) DISCLAIMS (I) ANY
        IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
        QUIET ENJOYMENT, OR NON-INFRINGEMENT; AND (II) ANY WARRANTIES ARISING
        OUT OF COURSE-OF-DEALING, USAGE, OR TRADE. YOU ASSUME ALL RISK FOR
        ANY/ALL DAMAGES THAT MAY RESULT FROM YOUR USE OF OR ACCESS TO THE SITE
        OR THE MEMBERSHIP SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER
        ADVOCACY INSTITUTE NOR ANYONE ASSOCIATED WITH ADVOCACY INSTITUTE
        REPRESENTS, WARRANTS OR GUARANTEES THE COMPLETENESS, SECURITY,
        RELIABILITY, QUALITY, AVAILABILITY OR ACCURACY OF, AND DISCLAIMS ALL
        LIABILITY FOR ANY ERRORS OR OTHER INACCURACIES IN, THE INFORMATION,
        CONTENT, RECOMMENDATIONS, AND MATERIALS MADE AVAILABLE THROUGH THE SITE
        OR THE MEMBERSHIP SERVICES. TO THE FULLEST EXTENT PERMITTED BY LAW, WE
        WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED
        DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL
        MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS,
        DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SITE OR THE
        MEMBERSHIP SERVICES OR ITEMS OBTAINED THROUGH THE SITE OR TO YOUR
        DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY SITE LINKED TO IT.
      </p>
      <h3>Limitation of Liability</h3>
      <p>
        IN NO EVENT WILL ADVOCACY INSTITUTE BE LIABLE TO YOU FOR ANY INCIDENTAL,
        SPECIAL, CONSEQUENTIAL, DIRECT, INDIRECT, OR PUNITIVE DAMAGES, WHETHER
        BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE, OR
        ANY OTHER LEGAL THEORY, WHETHER OR NOT ADVOCACY INSTITUTE HAS BEEN
        INFORMED OF THE POSSIBILITY OF SUCH DAMAGE. SOME JURISDICTIONS DO NOT
        ALLOW THE DISCLAIMER OF WARRANTIES OR LIMITATION OF LIABILITY IN CERTAIN
        CIRCUMSTANCES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY
        TO YOU.
      </p>
      <h3>Governing Law</h3>
      <p>
        These Terms are governed by the laws of the State of New York, without
        regard to conflict of law principles. Subject to Section 14, which
        provides that disputes are to be resolved through binding arbitration,
        to the extent that any lawsuit or court proceeding is permitted
        hereunder, You and Advocacy Institute agree to submit to the exclusive
        personal jurisdiction of the state courts and federal courts located
        within New York, New York, for the purpose of litigating all such
        disputes.
      </p>
      <h3>Dispute Resolution by Binding Arbitration</h3>
      <p>
        In the interest of resolving disputes between You and Advocacy Institute
        in the most expedient and cost effective manner, You and Advocacy
        Institute agree to resolve disputes through binding arbitration instead
        of in courts of general jurisdiction (“Agreement to Arbitrate”).
        Arbitration is more informal than a lawsuit in court. Arbitration uses a
        neutral arbitrator instead of a judge or jury, allows for more limited
        discovery than in court, and is subject to very limited review by
        courts. Arbitrators can award the same damages and relief that a court
        can award. Any arbitration or litigation under these Terms will take
        place on an individual basis; class arbitrations and class actions are
        not permitted. You acknowledge and agree that the arbitrator may award
        relief (including monetary, injunctive, and declaratory relief) only in
        favor of the individual party seeking relief and only to the extent
        necessary to provide relief necessitated by that individual party’s
        claim(s). Any relief awarded cannot affect other users.
      </p>
      <h3>Modification of the Site</h3>
      <p>
        Advocacy Institute reserves the right to modify or discontinue,
        temporarily or permanently, some or all of the Site or the Membership
        Services at any time without any notice or further obligation to you.
        You agree that Advocacy Institute will not be liable to you or to any
        third party for any modification, suspension, or discontinuance of any
        of the Site or the Membership Services.
      </p>
      <h3>General</h3>
      <ol type="A">
        <li>
          <span className="underline">Representations</span>. You, as an
          individual, represent, warrant and covenant that you are authorized to
          enter into these Terms on behalf of the entity which you represent, if
          any.
        </li>
        <li>
          <span className="underline">Survival</span>. Subject to the provisions
          in this paragraph, You may terminate these Terms by terminating Your
          access to the Site and receipt of Membership Services for a period of
          90 days. Renewed access or receipt re-establishes the effectiveness of
          these Terms. Advocacy Institute may terminate these Terms at any time
          on notice to You. All terms, obligations, and provisions of these
          Terms that contemplate obligation or performance subsequent to the
          termination of these Terms shall survive termination and continue in
          full force and effect and shall continue to bind the parties and their
          permitted successors and assigns.
        </li>
        <li>
          <span className="underline">Entire Agreement</span>. These Terms,
          together with, for the avoidance of doubt, the Privacy Policy, as well
          as legal and commercial terms set forth in an applicable order form,
          scope of work or similar document provided by Advocacy Institute,
          constitute the entire and exclusive understanding and agreement
          between You and Advocacy Institute regarding Your use of and access to
          the Site and the Membership Services, and except as expressly
          permitted above may only be amended by a written agreement signed by
          authorized representatives of the parties.
        </li>
        <li>
          <span className="underline">No Waiver</span>. The failure to require
          performance of any provision shall not affect our right to require
          performance at any time thereafter, nor shall a waiver of any breach
          or default of the Terms constitute a waiver of any subsequent breach
          or default or a waiver of the provision itself.
        </li>
        <li>
          <span className="underline">Paragraph Headers</span>. Use of paragraph
          headers in the Terms is for convenience only and shall not have any
          impact on the interpretation of particular provisions.
        </li>
        <li>
          <span className="underline">Severability</span>. In the event that any
          part of the Terms is held to be invalid or unenforceable, the
          unenforceable part shall be given effect to the greatest extent
          possible and the remaining parts will remain in full force and effect.
        </li>
      </ol>

      <h3>Notice to California Residents</h3>
      <p>
        Under California Civil Code Section 1789.3, You may contact the
        Complaint Assistance Unit of the Division of Consumer Services of the
        California Department of Consumer Affairs in writing at 1625 N. Market
        Blvd., Suite S-202, Sacramento, California 95834, or by telephone at
        (800) 952-5210 in order to resolve a complaint regarding the service or
        to receive further information regarding use of the service.
      </p>
    </div>
  );
}
export default TermsOfService;
