import React from "react";

import "../styles/Footer.css";

function Footer() {
  return (
    <div id="footer">
      <div>
        <a href="/privacy-policy" className="privacy-policy">
          Privacy Policy
        </a>
        <span className="vr"></span>
        <a href="/terms-of-service" className="terms-of-service">
          Terms of Service
        </a>
      </div>
      <p className="copyright">© Copyright 2020. The Advocacy Institute.</p>
      <h6>
        Questions? Email{" "}
        <a href="mailto:hello@advocacy-institute.org">
          hello@advocacy-institute.org
        </a>
      </h6>
      <a href="#header" id="back-to-top">
        <span className="material-symbols-outlined">keyboard_arrow_up</span>
      </a>
    </div>
  );
}

export default Footer;
