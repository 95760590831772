import { useEffect, useState } from "react";

import implementationImg from "../assets/images/implementation.png";
import { fieldNames } from "../utilities/constants";
import { Tool, sortTools } from "../utilities/utils";

const { CATALOGUES } = fieldNames;

function Implementation(props) {
  const [data, setData] = useState([]);
  const [implemItems, setImplemItems] = useState(3);

  useEffect(() => {
    let data = [];
    props.records.forEach((record) => {
      const catalogues = record.get(CATALOGUES);
      if (catalogues && catalogues.includes("Implementation")) {
        data.push(record);
      }
    });

    const sortedData = sortTools(data, props.sortInfo);

    setData(sortedData);
  }, [props.sortInfo, props.records]);

  const card = data.slice(0, implemItems).map((i) => Tool(i));

  return (
    <div id="implementation-container">
      <div className="lcatt-details">
        <img
          className="lcatt-img"
          src={implementationImg}
          alt="LCATT cycle with bill highlighted"
        />
        <h5 className="lcatt-text">
          Check out our
          <span>
            <a
              href="https://hi.advocacy-institute.org/lcatt/"
              target="_blank"
              rel="noreferrer"
            >
              Legislative Campaign Assessment and Tracking Tool
            </a>
          </span>
          for our bird’s-eye-diagram of the legislative advocacy process and a
          reminder of all the wins along the way.
        </h5>

        <h5 className="lcatt-text">
          Use these "Implementation" tools as you identify steps to take during
          and after a campaign to ensure the victory is reflective of the
          campaign's goals.
        </h5>
      </div>

      {card}
      {data.length === null || implemItems >= data.length ? null : (
        <button onClick={() => setImplemItems(implemItems + 3)}>
          see more
        </button>
      )}
    </div>
  );
}

export default Implementation;
