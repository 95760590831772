import React from "react";

import "../styles/Header.css";

function Header(props) {
  return (
    <div id="header">
      <a href="/">
        <img src={props.headerImage} className="header-image" alt="AI Logo" />
      </a>
      <h2 className="header-text">{props.logoText}</h2>
    </div>
  );
}

export default Header;
