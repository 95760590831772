import React from "react";

import { trackToolClicked } from "../utilities/utils";
import "../styles/CardItem.css";

function CardItem(props) {
  return (
    <div
      className={
        props.itemTag != null && props.itemSubTag != null
          ? "card-item-container"
          : "featured-item-container"
      }
    >
      <a
        href={props.itemLink}
        className="card-item-link"
        target="_blank"
        rel="noreferrer"
        onClick={() => trackToolClicked(props.itemTitle, props.itemTag)}
      >
        <img
          src={props.itemImage}
          className="card-item-img"
          alt="item format"
        />
        <div className="card-item-text">
          <h2 className="card-item-title">{props.itemTitle}</h2>
          <p className="card-item-desc">{props.itemDesc}</p>
        </div>

        <div className="tags">
          <button
            className={props.itemTag != null ? "display-tag" : "hidden-tag"}
          >
            {props.itemTag}
          </button>
          <button
            className={
              props.itemSubTag == null
                ? "hidden-tag"
                : props.itemSubTag.length > 11
                ? "resize display-tag"
                : "display-tag"
            }
          >
            {props.itemSubTag}
          </button>
        </div>
      </a>
    </div>
  );
}

export default CardItem;
