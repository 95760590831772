import React, { useState } from "react";

import "../styles/ListInfo.css";

import VideoPopup from "./VideoPopup";

function ListInfo() {
  const [showVideoOne, setShowVideoOne] = useState(false);
  const closeVideoOne = () => setShowVideoOne(false);
  const openVideoOne = () => setShowVideoOne(true);

  const [showVideoTwo, setShowVideoTwo] = useState(false);
  const closeVideoTwo = () => setShowVideoTwo(false);
  const openVideoTwo = () => setShowVideoTwo(true);

  return (
    <h5 className="list-info-heading">
      Here you can find AI's searchable database of elected officials and their
      staff, the AI Legislative Directory, as well as lists of legislators and
      staff at various levels of government. Learn more about using lists{" "}
      <span className="list-info-link" onClick={openVideoOne}>
        here
      </span>{" "}
      <VideoPopup
        show={showVideoOne}
        close={closeVideoOne}
        title="Learn more about using lists"
        src="https://www.loom.com/embed/7770083e50ed41078516481f96d8e50f"
      />
      and the Legislative Directory{" "}
      <span className="list-info-link" onClick={openVideoTwo}>
        here
      </span>
      <VideoPopup
        show={showVideoTwo}
        close={closeVideoTwo}
        title="Learn more about the Legislative Directory"
        src="https://www.loom.com/embed/e33f7e40f2a447fb8e9305dd7b3c7f03"
      />
      . If you prefer AI to pull a list for you, request lists{" "}
      <a
        href="https://airtable.com/shr49NADdD9nvYPwD"
        target="_blank"
        rel="noreferrer"
      >
        here
      </a>
      .
    </h5>
  );
}

export default ListInfo;
