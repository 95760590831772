import { sort } from "fast-sort";
import ReactGA4 from "react-ga4";

import CardItem from "../components/CardItem";

export function Tool(i) {
  return (
    <CardItem
      key={i.id}
      itemLink={i.fields.LINK}
      itemImage={
        i.fields.IMAGES && i.fields.IMAGES[0].url
          ? i.fields.IMAGES[0].url
          : "https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png?w=640"
      }
      itemTag={i.fields.FORMAT}
      itemSubTag={i.fields["SUB-FORMAT"]}
      itemTitle={i.fields["TOOL NAME"]}
      itemDesc={i.fields.DESCRIPTION}
    />
  );
}

// This is the method used to sort tools according to the logic stored in the "Sort Fields"
// table in the MyTools Base in Airtable. See utils.test.js for example usage and test cases.
export function sortTools(data, sortInfo) {
  // Only sort the tools if we have the sortInfo which tells us how to sort them (eg. alphabetically, ascending)
  // If we are missing sortInfo, just return the data as it is passed in
  if (sortInfo) {
    let sortedData = {};
    let sortedTopTools = [];
    let subsetSortedData = [];

    sortInfo.forEach((record) => {
      // `group` is the subgroup a tool might be a part of. We call that field "TOGGLE SECTION"
      // because tools are hidden by default but can be "toggled" to display the list of tools.
      // eg. the format of the tool (pdf, webinar, etc.) or a region (New York State, Long Island, etc.)
      const toggleSection = record.get("TOGGLE SECTION");
      const group =
        toggleSection && toggleSection.toLowerCase().split(" ").join("");

      // All records will have sort field 1 and sort order 1, but not all records have sort field 2 and sort order 2.
      const sortFieldOne = record.get("SORT FIELD 1 LOOKUP");
      const sortOrderOne = record.get("SORT FIELD 1 ORDER");
      const sortFieldTwo = record.get("SORT FIELD 2 LOOKUP");
      const sortOrderTwo = record.get("SORT FIELD 2 ORDER");

      // First, we have to split the top tools up from the rest of the data so that we can sort them separately.
      // Not all records will have top tools defined.
      // If there are no top tools, this function returns an empty array for `topTools`
      const [topTools, dataToSort] = getSplitData(data, record, group);

      if (sortFieldTwo) {
        // Sort the top tools
        sortedTopTools = sortWithTwoSortFields(
          topTools,
          sortFieldOne,
          sortOrderOne,
          sortFieldTwo,
          sortOrderTwo
        );
        // Sort the remaining data
        subsetSortedData = sortWithTwoSortFields(
          dataToSort,
          sortFieldOne,
          sortOrderOne,
          sortFieldTwo,
          sortOrderTwo
        );
      } else {
        sortedTopTools =
          sortOrderOne === "Ascending"
            ? sortAscending(topTools, sortFieldOne)
            : sortDescending(topTools, sortFieldOne);

        subsetSortedData =
          sortOrderOne === "Ascending"
            ? sortAscending(dataToSort, sortFieldOne)
            : sortDescending(dataToSort, sortFieldOne);
      }
      // Combine the top tools with the rest of the sorted data and store it under the
      // appropriate group (format or region)
      if (group) {
        sortedData[group] = sortedTopTools.concat(subsetSortedData);
      } else {
        sortedData = sortedTopTools.concat(subsetSortedData);
      }
    });
    return sortedData;
  } else {
    return data;
  }
}

// This method splits the top tools from the rest of the data so they can be sorted separately
const getSplitData = (data, record, group) => {
  // If the data is grouped (by region or format), only sort the data within that group
  const subsetData = group ? data[group] : data;
  const topToolsToolNames = record.get("TOP 3 TOOLS LOOKUP");

  let topTools = [];
  let dataToSort = [];

  // If top tools are defined in Airtable, split them out here
  if (topToolsToolNames) {
    subsetData.forEach((record) => {
      let toolName = record.fields["TOOL NAME"];
      if (topToolsToolNames.indexOf(toolName) !== -1) {
        topTools.push(record);
      } else {
        dataToSort.push(record);
      }
    });
  } else {
    // If we don't have top tools specified, just reassign the original data array
    dataToSort = subsetData;
  }
  return [topTools, dataToSort];
};

// This method makes use of the `sort` method from the `fast-sort` library to sort by two rules
const sortWithTwoSortFields = (
  data,
  sortFieldOne,
  sortOrderOne,
  sortFieldTwo,
  sortOrderTwo
) => {
  let sortFunctionOne = {};
  let sortFunctionTwo = {};

  if (sortOrderOne === "Ascending") {
    sortFunctionOne = { asc: (t) => t.fields[sortFieldOne] };
  } else {
    sortFunctionOne = { desc: (t) => t.fields[sortFieldOne] };
  }

  if (sortOrderTwo === "Ascending") {
    sortFunctionTwo = { asc: (t) => t.fields[sortFieldTwo] };
  } else {
    sortFunctionTwo = { desc: (t) => t.fields[sortFieldTwo] };
  }

  // the data is first sorted by sortFunctionOne (eg. format - descending) and then by
  // sortFunctionTwo (eg. tool name - ascending)
  return sort(data).by([sortFunctionOne, sortFunctionTwo]);
};

// This is a helper method that uses the `sort` function from the `fast-sort` library to sort data
// in an ascending order
const sortAscending = (data, sortField) => {
  return sort(data).asc([
    (t) => {
      return t.fields[sortField];
    },
  ]);
};

// This is a helper method that uses the `sort` function from the `fast-sort` library to sort data
// in a descending order
const sortDescending = (data, sortField) => {
  return sort(data).desc([(t) => t.fields[sortField]]);
};

//tracks tools that are clicked in google analytics based on tool type and tool title
export const trackToolClicked = (toolName, toolType) => {
  ReactGA4.event("tool_clicked", {
    tool_name: toolName,
    tool_type: toolType,
  });
};

//tracks search term from search bar
export const trackSearchTerm = (searchTerm) => {
  ReactGA4.event("search_term", { search_term: searchTerm });
};

//tracks dropdown item selected
export const trackDropdownItem = (itemSelected) => {
  ReactGA4.event("dropdown_item", { dropdown_item: itemSelected });
};
