import { useEffect, useState } from "react";

import CardItem from "./CardItem";
import { fieldNames } from "../utilities/constants";

const { CATALOGUES } = fieldNames;

function Featured(props) {
  const [data, setData] = useState([]);

  useEffect(() => {
    let data = [];
    props.records.forEach((record) => {
      const catalogues = record.get(CATALOGUES);
      if (catalogues && catalogues.includes("Featured")) {
        data.push(record);
      }
    });
    setData(data);
  }, [props.records]);

  const card = data.map((i) => (
    <CardItem
      key={i}
      itemLink={i.fields.LINK}
      itemImage={i.fields.IMAGES[0].url}
      itemTitle={i.fields["TOOL NAME"]}
      itemDesc={i.fields.DESCRIPTION}
    />
  ));
  return <div id="featured-container"> {card} </div>;
}

export default Featured;
