import React from "react";

import "../styles/Policies.css";

function PrivacyPolicy() {
  return (
    <div className="policy-container">
      <h3>Privacy Policy</h3>
      <p>Last Updated: 8/23/2023</p>
      <p>
        This Privacy Policy (this “Privacy Policy” or “Policy”) applies to your
        access and use of all products and services that are made available by
        the Advocacy Institute, including without limitation the products and
        services made available through or in relation to the Advocacy
        Institute’s website, located at advocacy-institute.org (the “Website”),
        and is incorporated into and is subject to the{" "}
        <a href="/terms-of-service">Advocacy Institute’s Terms of Use</a> (the
        “Terms”). Capitalized terms that are not defined in the Privacy Policy
        have the meaning given to them in the Terms. This Privacy Policy only
        applies to information collected (i) on the Website and related
        web-based sites or platforms provided to you (collectively, including
        without limitation any content hosted by the Advocacy Institute thereon
        or provided by the Advocacy Institute in connection therewith, the
        “Site”); and (ii) in connection with the services provided to you by the
        Advocacy Institute which may include, without limitation, trainings,
        consultation, and information regarding political advocacy (the
        “Membership Services.”). It describes the information that we gather
        from you, how we use and disclose your information, and the steps we
        take to protect your information. By using the Site or Membership
        Services, you consent to the privacy practices described in this Policy.
      </p>
      <h3>The information that we collect</h3>
      <ul>
        <li>Personal Information</li>
        <p>
          We collect certain types of information from and about users of the
          Site or Membership Services directly from you when you provide it to
          us or third parties authorized to share it with us, as well as
          automatically through your use of the Site or Membership Services. If
          you provide us with Personal Information, we will store and use that
          information in accordance with this Policy (when we use the term
          “Personal Information,” we mean information that can be associated
          with a specific person and can be used to identify that person, such
          as name, e-mail address, mailing address, telephone number,
          demographic, and other similar types of information). For example, you
          may provide us with Personal Information when you fill out forms or
          fields on the Site or in connection with Membership Services; when you
          register for a membership account; when you sign up for our
          newsletter; when you access tools on the website including, but not
          limited to, My Tools and Interactive Snapshots; when you make a
          donation through the Site; when you register for and/or participate in
          trainings; and when you contact us or request information about us,
          this Site and our products or services (whether by email, text, social
          media, or other means).
        </p>
        <li> Cookies Information</li>
        <p>
          When you use the Site, we may send one or more “Cookies” (which are
          small text files containing a string of alphanumeric characters) to
          your computer or mobile device, to help analyze our web page flow,
          customize our content, and promote trust and safety. Cookies also
          remember your preferences, which may allow you to navigate between
          pages more efficiently and improve your browsing experience. You are
          always free to decline our Cookies if your browser permits, although
          doing so may interfere with your ability to use the Site or certain
          features of the Site. We may also use Google Analytics, or a similar
          service which uses Cookies, to help us analyze how users use the Site.
        </p>
        <li>Automatically Collected Information</li>
        <p>
          When you visit the Site, we may automatically receive and record
          certain information from your computer, web browser and/or mobile
          device, including without limitation your domain, IP address, or other
          device address or ID, web browser and/or device type, operating
          system, hardware and software settings and configurations, the pages
          you view on the Site, your actions on the Site, and the dates and
          times that you visit, access, or use the Site (“Automatically
          Collected Information”). When you use the Site on a mobile device, we
          may also collect the physical location of your device by, for example,
          using satellite, cell phone tower or wireless local area network
          signals, and how long you visit, access, or use the Site.
        </p>
      </ul>
      <h3>How we use the information we collect</h3>
      <ul>
        <li>
          We use non-identifying information about your use of the Site or the
          Membership Services to understand and analyze the usages trends and
          preferences of our users, to improve our Site and services and tools,
          to better communicate with you, for marketing purposes, and/or to
          improve fraud detection and information security.
        </li>
        <li>
          We may use your e-mail address or other Personal Information to
          contact you for administrative purposes such as customer service,
          and/or to send you promotional materials. Generally, you can opt-out
          of receiving promotional communications using the unsubscribe link in
          the promotional emails that you receive from the Advocacy Institute.
          You can update or delete the information that you have provided to us
          by contacting us directly at hello@advocacy-institute.org. Although
          your changes are reflected promptly in active user databases, we may
          retain all information you submit for a variety of purposes, including
          backups and archiving, prevention of fraud and abuse, and analytics.
        </li>
        <li>
          We may use Cookies and Automatically Collected Information to: (a)
          personalize our services, such as remembering your information so that
          you will not have to re-enter it during your visit or the next time
          you visit the Site; (b) provide customized content and information;
          (c) monitor and analyze the effectiveness of the Site; and (d) monitor
          aggregate site usage metrics such as total number of visitors and
          pages viewed.
        </li>
      </ul>
      <h3>When we disclose information</h3>
      <ul>
        <li>
          We may disclose your information if required to do so by law or in the
          good-faith belief that such action is necessary to take precautions
          against liability; to protect the Advocacy Institute from fraudulent,
          abusive, or unlawful uses or activity; to protect the security or
          integrity of the Site; to investigate and defend ourselves against any
          third party claims or allegations; to assist government enforcement
          agencies; or to comply with state and federal laws, in response to a
          court order, judicial or other government subpoena or warrant.
        </li>
        <li>
          We may disclose your Personal Information to third-party service
          providers that we engage to help us provide the Membership Services or
          operate and maintain the Site, store data and administer activities on
          our behalf including, but not limited to, Quickbooks, HubSpot, Ramp,
          Airtable, Givebutter, Magiclink, Zapier and, Signal. If these
          third-party service providers have access to your Personal
          Information, they are required to protect the confidentiality of the
          information and use it only for the limited purpose for which it was
          provided. For your information and convenience, this Site may contain
          links to third-party sites. These third-party websites are not under
          our control and are governed by their own privacy policies and terms
          of use. The Advocacy Institute’s responsibility and liability ends
          once you have entered the third-party’s website.
        </li>
        <li>
          In the event the Advocacy Institute were to be engaged in or
          contemplating a divestiture, merger, consolidation, or asset sale, or
          in the unlikely event of a bankruptcy, the Advocacy Institute may
          transfer or assign the data, including Personal Information, that we
          have collected from users.
        </li>
      </ul>
      <h3>Your Choices</h3>
      <ul>
        <li>
          You may, of course, decline to share certain information with us, in
          which case we may not be able to provide to you some of the features
          and functionality of the Site.
        </li>
        <li>
          Once you have registered for a membership or other account on the
          Site, you may update or delete the information that you have provided
          to us by contacting us directly at hello@advocacy-institute.org.
          Although your changes are reflected promptly in active user databases,
          we may retain all information you submit for a variety of purposes,
          including backups and archiving, prevention of fraud and abuse, and
          analytics, as consistent with applicable law.
        </li>
        <li>
          If you do not wish to receive email solicitations or newsletters from
          us, you can opt-out of receiving email information from us (except for
          emails related to the completion of your registration, correction of
          user data, change of password, and other similar communications
          essential to your transactions through the Site) by using the
          unsubscribe link in the promotional emails that you receive from the
          Advocacy Institute. Although your changes are reflected promptly in
          active user databases, we may retain all information you submit for a
          variety of purposes, including backups and archiving, prevention of
          fraud and abuse, and analytics.
        </li>
      </ul>
      <h3>Our Commitment to Children’s Privacy</h3>
      <p>
        Protecting the privacy of young children is especially important. For
        that reason, we do not knowingly collect or maintain information from
        persons under 13 years of age, and no part of the Site is directed to
        persons under 13 years of age. If you are under 13 years of age, then
        please do not use or access the Site at any time or in any manner. If we
        learn that information has been collected through the Site from persons
        under 13 years of age and without verifiable parental consent, then we
        will take the appropriate steps to delete this information. If you are a
        parent or guardian and discover that your child under 13 years of age
        has provided us with information, please alert us at
        hello@advocacy-institute.org to request that we delete the information
        from our systems.
      </p>
      <h3>Our Commitment to Data Security</h3>
      <p>
        We use certain physical, managerial, and technical safeguards that are
        designed to protect the integrity and security of your information;
        however, no security measures are perfect or impenetrable, so we cannot
        ensure or warrant the security of any information you transmit to us
        through the Site or the Membership Services, and you do so at your own
        risk. We also cannot guarantee that such information may not be
        accessed, disclosed, altered, or destroyed by breach of any of our
        physical, technical, or managerial safeguards. We cannot control the
        actions of other users or other third parties with whom you may choose
        to share your information and therefore we cannot and do not guarantee
        that information you post through the Site will not be viewed by
        unauthorized persons. We are not responsible for circumvention of any
        privacy settings or security measures contained on the Site. Even after
        removal, copies of information that you have posted may remain viewable
        in cached and archived pages or if other users have copied or stored
        such information. We retain information as long as it is reasonably
        necessary and relevant for our operations, and/or to comply with the
        law, prevent fraud, collect any fees owed, resolve disputes,
        troubleshoot problems, assist with any investigation, or enforce our
        Terms or other agreements.
      </p>
      <h3>Visitors from Outside the United States</h3>
      <p>
        This Site and the Membership Services are controlled and operated by the
        Advocacy Institute in the United States. This Site and our services are
        not directed or targeted to persons or entities located outside the
        United States. If you choose to access the Site or use the Membership
        Services from outside the United States, you acknowledge that you will
        be transferring your information, including Personal Information,
        outside of those regions to the United States for storage and
        processing, as necessary to provide to you the products and services
        available through the Site. Where required, we comply with applicable
        legal frameworks relating to the collection, storage, use, and transfer
        of personal information. Depending on the jurisdiction in which you
        reside, you may have the right to access, correct, delete, or restrict
        use of certain Personal Information covered by this Privacy Policy.
        Depending on the jurisdiction, you may also have the right to request
        that we refrain from processing your Personal Information. Please bear
        in mind that if you exercise such rights this may affect our ability to
        provide our products and services. For inquiries about your Personal
        Information, please contact us by e-mail at
        hello@advocacy-institute.org, or through any of the contact information
        provided below. While the Advocacy Institute will make reasonable
        efforts to accommodate your request, we also reserve the right to impose
        certain restrictions and requirements on such requests, if allowed or
        required by applicable laws. Please note that it may take some time to
        process your request, consistent with applicable law.
      </p>
      <h3>Limitation of Liability</h3>
      <p>
        By accessing this Site and/or providing us with Personal Information and
        other data, you expressly and unconditionally release and hold us
        harmless from any and all liability for any injuries, loss, or damage of
        any kind arising from or in connection with the use and/or misuse of
        such information. In addition, while we take efforts to ensure the
        proper and appropriate use of data by our service providers that may
        receive your information from us, we are not responsible for any
        injuries, loss, or damage of any kind arising from or in connection with
        the use and/or misuse of your information caused by those service
        providers.
      </p>

      <h3>Changes and Updates to this Privacy Policy</h3>
      <p>
        We reserve the right to make changes to this Privacy Policy at any time.
        We will notify you about significant changes in the way we treat your
        information, including by placing a prominent notice on the Site or by
        sending you an email so that you can choose whether to continue using
        the Site. Material modifications are effective 30 calendar days after
        our initial notification or upon your acceptance of the modified Terms.
        Immaterial modifications are effective upon posting of the updated
        Privacy Policy or Terms of Service on the Site. Please revisit this page
        periodically to stay aware of any changes to this Privacy Policy. For
        the avoidance of doubt, disputes arising hereunder will be resolved in
        accordance with the Privacy Policy in effect at the time the dispute
        arose.
      </p>

      <h3>Do Not Track Disclosures</h3>
      <p>
        Third parties such as advertising networks, analytics providers, and
        widget providers may collect information about your online activities
        over time and across different websites when you access or use this Site
        or the Membership Services. Currently, various browsers offer a “Do Not
        Track” option, but there is no standard for how DNT should work on
        commercial websites. Due to lack of such standards, the Site does not
        respond to Do Not Track consumer browser settings.
      </p>
      <h3>Our Contact Information</h3>
      <p>
        Please contact us with any questions or comments about this Privacy
        Policy, your personal information, our use and disclosure practices, or
        your consent choices by e-mail at hello@advocacy-institute.org.
      </p>
      <p className="text-black">
        Attn: Privacy
        <br />
        Advocacy Institute
        <br />
        c/o cori schmanke parrish
        <br />
        224 W. 35th St. Suite 500 #23
        <br />
        New York, NY 10001
        <br />
        (917) 398-3820
        <br />
      </p>
    </div>
  );
}
export default PrivacyPolicy;
