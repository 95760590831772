import "../styles/VideoContainer.css";

function VideoContainer(props) {
  return (
    <div className="video-container">
      <h6 className="video-title">{props.title}</h6>
      <iframe
        src={props.src}
        frameBorder="0"
        allowFullScreen
        webkitAllowFullscreen
        title="video container"
      ></iframe>
    </div>
  );
}

export default VideoContainer;
