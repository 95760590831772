import { useEffect, useState } from "react";

import { fieldNames } from "../utilities/constants";
import { Tool, sortTools } from "../utilities/utils";

const { CATALOGUES } = fieldNames;

function Webinars(props) {
  const [data, setData] = useState([]);
  const [webinarItems, setWebinarItems] = useState(3);

  useEffect(() => {
    let data = [];
    props.records.forEach((record) => {
      const catalogues = record.get(CATALOGUES);
      if (catalogues && catalogues.includes("Webinars")) {
        data.push(record);
      }
    });

    const sortedData = sortTools(data, props.sortInfo);

    setData(sortedData);
  }, [props.sortInfo, props.records]);

  const card = data.slice(0, webinarItems).map((i) => Tool(i));
  return (
    <div id="webinars-container">
      {card}
      {data.length === null || webinarItems >= data.length ? null : (
        <button onClick={() => setWebinarItems(webinarItems + 3)}>
          see more
        </button>
      )}
    </div>
  );
}

export default Webinars;
