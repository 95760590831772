import { useState } from "react";

import "../styles/SearchBar.css";
import { fieldNames } from "../utilities/constants";
import { Tool } from "../utilities/utils";

const { DESCRIPTION, TOOL_NAME, TAGS } = fieldNames;

function SearchBar(props) {
  const [numOfItems, setNumOfItems] = useState(3);

  // grab the search input and split search terms up into an array
  let searchTerms = document
    .getElementById("search-input")
    .value.toLowerCase()
    .split(" ");

  let filteredItems = props.records;

  // loop through the array of search terms and filter records.
  // included records must include all search terms.
  searchTerms.forEach((term) => {
    let tempFilteredItems = [];

    filteredItems.forEach((item) => {
      const tagsField = item.fields[TAGS];
      let tags = [];

      if (tagsField) {
        tags = tagsField.map((tag) => {
          return tag.toLowerCase();
        });
      }
      const termInDescription =
        item.fields[DESCRIPTION] &&
        item.fields[DESCRIPTION].toLowerCase().includes(term);
      const termInTitle = item.fields[TOOL_NAME].toLowerCase().includes(term);
      const termInTags = tagsField && tags.includes(term);

      if (termInDescription || termInTitle || termInTags) {
        tempFilteredItems.push(item);
      }
      filteredItems = tempFilteredItems;
    });
  });

  const card = filteredItems.map((i) => Tool(i));

  return (
    <div id="results-container">
      <h5 className="results-tooltip">
        <span> {filteredItems.length} </span> All tools including "
        <span>{searchTerms.join(" ")}</span>"
      </h5>
      {card.slice(0, numOfItems)}
      {card.length === null || numOfItems >= card.length ? null : (
        <button onClick={() => setNumOfItems(numOfItems + 3)}>
          {" "}
          see more{" "}
        </button>
      )}
    </div>
  );
}

export default SearchBar;
