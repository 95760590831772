import { useEffect, useState } from "react";

import { Accordion } from "react-bootstrap";

import ListInfo from "./ListInfo";
import { fieldNames } from "../utilities/constants";
import { Tool, sortTools } from "../utilities/utils";

const { FORMAT, CATALOGUES } = fieldNames;

function FindLegislators(props) {
  //initializing the arrays that will receive data from api as empty arrays
  const [state, setState] = useState({
    pdfData: [],
    webData: [],
    listData: [],
  });

  // initializing items to be at 3
  const [items, setItems] = useState({
    pdfItems: 3,
    webItems: 3,
    listItems: 3,
  });

  useEffect(() => {
    let data = {
      audio: [],
      pdf: [],
      video: [],
      website: [],
      list: [],
    };
    props.records.forEach((record) => {
      const catalogues = record.get(CATALOGUES);
      if (catalogues && catalogues.includes("Find Legislators")) {
        let format = record.get(FORMAT).toLowerCase(); // will return audio, pdf, video, website, or list
        data[format].push(record);
      }
    });

    const sortedData = sortTools(data, props.sortInfo);

    setState({
      audioData: sortedData.audio,
      pdfData: sortedData.pdf,
      videoData: sortedData.video,
      webData: sortedData.website,
      listData: sortedData.list,
    });
  }, [props.sortInfo, props.records]);

  //CardItems component will render with data based off the data we received from api
  //CardItems rendered will be based off its corresponding items number

  const pdfList = state.pdfData.slice(0, items.pdfItems).map((i) => Tool(i));

  const webList = state.webData.slice(0, items.webItems).map((i) => Tool(i));

  const lists = state.listData.slice(0, items.listItems).map((i) => Tool(i));

  return (
    <div id="find-legislators-container">
      <Accordion>
        <Accordion.Item eventKey="0">
          {/* will render each list with initially only 3 items, each 'see more' button will update each
      corresponding state by 3, allowing each list to show 3 more items. */}
          <Accordion.Header>PDF</Accordion.Header>
          <Accordion.Body>
            {pdfList}
            {state.pdfData.length === null ||
            items.pdfItems >= state.pdfData.length ? null : (
              <button
                onClick={() =>
                  setItems({ ...items, pdfItems: items.pdfItems + 3 })
                }
              >
                see more
              </button>
            )}
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>WEB</Accordion.Header>
          <Accordion.Body>
            {webList}
            {state.webData.length === null ||
            items.webItems >= state.webData.length ? null : (
              <button
                onClick={() =>
                  setItems({ ...items, webItems: items.webItems + 3 })
                }
              >
                see more
              </button>
            )}
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>LISTS OF ELECTEDS AND STAFF</Accordion.Header>
          <Accordion.Body>
            <ListInfo />
            {lists}
            {state.listData.length === null ||
            items.listItems >= state.listData.length ? null : (
              <button
                onClick={() =>
                  setItems({ ...items, listItems: items.listItems + 3 })
                }
              >
                see more
              </button>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

export default FindLegislators;
