import { Modal, Button } from "react-bootstrap";

import "../styles/HelpPopup.css";

import VideoContainer from "./VideoContainer";
import userGuide from "../assets/documents/My-Tools-User-Guide.pdf";
import docImg from "../assets/images/document-image.png";

function HelpPopup(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.close}
      className="help-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Learn how to use My Tools</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="video-section">
          <h5>Use these videos to get an overview of My Tools.</h5>

          <div className="videos">
            <VideoContainer
              title="Intro to My Tools"
              src="https://www.loom.com/embed/21b15a120c844b28a02ba61383eaaabd"
            />

            <VideoContainer
              title="Using My Tools"
              src="https://www.loom.com/embed/518f4e4fcb304061abf5fc45667545e1"
            />
          </div>
        </div>

        <div className="video-section">
          <h5>
            Use these videos to understand how to use the AI Legislative
            Directory.
          </h5>
          <div className="videos">
            <VideoContainer
              title="Intro to Legislative Directory"
              src="https://www.loom.com/embed/e33f7e40f2a447fb8e9305dd7b3c7f03"
            />

            <VideoContainer
              title="Pulling Lists of Legislators"
              src="https://www.loom.com/embed/7770083e50ed41078516481f96d8e50f"
            />
          </div>
        </div>

        <p className="video-instructions">
          <img src={docImg} className="document-image" alt="Document" />
          Download a PDF version of these instructions{" "}
          <a href={userGuide} download>
            here
          </a>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={props.close}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default HelpPopup;
