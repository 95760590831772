import React, { useState, useEffect } from "react";

import Airtable from "airtable";
import { Dropdown, Button, Popover, OverlayTrigger } from "react-bootstrap";

import AllTools from "../components/AllTools";
import ChineseTools from "../components/ChineseTools";
import CoalitionBuilding from "../components/CoalitionBuilding";
import DevelopingAnAsk from "../components/DevelopingAnAsk";
import Featured from "../components/Featured";
import FindLegislators from "../components/FindLegislators";
import HelpPopup from "../components/HelpPopup";
import Implementation from "../components/Implementation";
import LegBudProcess from "../components/LegBudProcess";
import RelBuilding from "../components/RelBuilding";
import SearchBar from "../components/SearchBar";
import SettingTheStage from "../components/SettingTheStage";
import SpanishTools from "../components/SpanishTools";
import Timelines from "../components/Timelines";
import ToolsByRegion from "../components/ToolsByRegion";
import VideoPopup from "../components/VideoPopup";
import Webinars from "../components/Webinars";
import { fieldNames } from "../utilities/constants";
import { logoutUser } from "../utilities/magic";
import { trackSearchTerm, trackDropdownItem } from "../utilities/utils";
import "../styles/Home.css";

const { PUBLISH } = fieldNames;
const base_id = process.env.REACT_APP_BASE_ID;
const personal_access_token =
  process.env.REACT_APP_AIRTABLE_PERSONAL_ACCESS_TOKEN;
Airtable.configure({ apiKey: personal_access_token });
const base = Airtable.base(base_id);

function MyTools({ setIsLoggedIn }) {
  const [title, setTitle] = useState("ALL TOOLS");
  const [records, setRecords] = useState([]);
  const [inputVal, setInputVal] = useState("");
  const [showHelpPopup, setShowHelpPopup] = useState(false);
  const [showVideoPopup, setShowVideoPopup] = useState(false);
  const [sortInfo, setSortInfo] = useState({
    allTools: [],
    findLegislators: [],
    timelines: [],
    webinars: [],
    toolsByRegion: [],
    settingTheStage: [],
    coalitionBuilding: [],
    developingAnAsk: [],
    legislativeBudgetProcess: [],
    implementation: [],
    spanishTools: [],
    chineseTools: [],
  });

  const closeHelpPopup = () => setShowHelpPopup(false);
  const openHelpPopup = () => setShowHelpPopup(true);

  const closeVideoPopup = () => setShowVideoPopup(false);
  const openVideoPopup = () => setShowVideoPopup(true);

  useEffect(() => {
    const results = () => {
      let data = [];
      let sortInfoHolder = {
        allTools: [],
        findLegislators: [],
        timelines: [],
        webinars: [],
        toolsByRegion: [],
        settingTheStage: [],
        coalitionBuilding: [],
        developingAnAsk: [],
        relaltionshipBuilding: [],
        legislativeBudgetProcess: [],
        implementation: [],
        spanishTools: [],
        chineseTools: [],
      };

      base("Tools")
        .select({
          view: "All Tools",
        })
        .eachPage(
          function page(records, fetchNextPage) {
            // This function (`page`) will get called for each page of records.
            records.forEach(function (record) {
              if (record.get(PUBLISH)) {
                data.push(record);
              }
            });
            // To fetch the next page of records, call `fetchNextPage`.
            // If there are more records, `page` will get called again.
            // If there are no more records, `done` will get called.
            fetchNextPage();
          },
          function done(err) {
            if (err) {
              console.error(err);
            } else {
              setRecords(data);
            }
          }
        );

      base("Sort Fields")
        .select({
          view: "Grid view",
        })
        .eachPage(
          function page(records, fetchNextPage) {
            records.forEach(function (record) {
              let collection = record.get("COLLECTION");
              // organize sort info into collections here so we can we can pass only the necessary data to each component
              switch (collection) {
                case "All Tools":
                  sortInfoHolder.allTools.push(record);
                  break;
                case "Find Legislators":
                  sortInfoHolder.findLegislators.push(record);
                  break;
                case "Timelines":
                  sortInfoHolder.timelines.push(record);
                  break;
                case "Webinars":
                  sortInfoHolder.webinars.push(record);
                  break;
                case "Tools by Region":
                  sortInfoHolder.toolsByRegion.push(record);
                  break;
                case "Setting the Stage":
                  sortInfoHolder.settingTheStage.push(record);
                  break;
                case "Coalition Building":
                  sortInfoHolder.coalitionBuilding.push(record);
                  break;
                case "Developing an Ask":
                  sortInfoHolder.developingAnAsk.push(record);
                  break;
                case "Relationship Building":
                  sortInfoHolder.relaltionshipBuilding.push(record);
                  break;
                case "Legislative/Budget Process":
                  sortInfoHolder.legislativeBudgetProcess.push(record);
                  break;
                case "Implementation":
                  sortInfoHolder.implementation.push(record);
                  break;
                case "Spanish Tools":
                  sortInfoHolder.spanishTools.push(record);
                  break;
                case "Chinese Tools":
                  sortInfoHolder.chineseTools.push(record);
                  break;
                default:
                  break;
              }
            });

            fetchNextPage();
          },
          function done(err) {
            if (err) {
              console.error(err);
            } else {
              setSortInfo(sortInfoHolder);
            }
          }
        );
    };
    results();
  }, []);

  useEffect(() => {
    const search = setTimeout(() => {
      if (inputVal !== "") {
        trackSearchTerm(inputVal);
      }
    }, 1500);

    return () => {
      clearTimeout(search);
    };
  }, [inputVal]);

  const handleLogOut = async () => {
    try {
      await logoutUser(setIsLoggedIn);
    } catch (error) {
      console.error(error);
    }
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        Learn more about browsing tools{" "}
        <span onClick={openVideoPopup} className="tooltip-link">
          here
        </span>
      </Popover.Body>
    </Popover>
  );

  return (
    <div id="home-container">
      <div id="main-text">
        <div className="header-buttons">
          <Button variant="outline-primary" onClick={openHelpPopup}>
            Need Help?
          </Button>

          <Button variant="primary" onClick={handleLogOut}>
            Sign Out
          </Button>
        </div>
        <p>
          Legislative advocacy is complex. We aim to break it down so advocates
          can better understand it and move through it.
        </p>
        <p>
          People often focus on the “big win” of passing legislation, but
          campaigns have many wins along the way (and after the bill has
          passed). We’ve developed a suite of tools to help you achieve your
          wins on the way to successful campaigns.
        </p>
      </div>
      <h3 className="featured-tool-heading">FEATURED TOOL</h3>

      {records.length > 0 && <Featured records={records} />}

      <h3 id="search-heading">SEARCH</h3>
      <div id="search-results">
        <input
          type="text"
          id="search-input"
          placeholder="e.g. budget process"
          onChange={(e) => setInputVal(e.target.value)}
        />
        {inputVal !== "" ? <SearchBar records={records} /> : null}
      </div>

      <h3 id="dropdown-heading">
        BROWSE TOOLS
        <OverlayTrigger
          trigger="click"
          placement="auto"
          overlay={popover}
          rootClose
        >
          <span
            className="material-symbols-outlined"
            id="browse-tools-help-icon"
          >
            help_center
          </span>
        </OverlayTrigger>
      </h3>

      <VideoPopup
        show={showVideoPopup}
        close={closeVideoPopup}
        title="Learn more about browsing tools"
        src="https://www.loom.com/embed/518f4e4fcb304061abf5fc45667545e1"
      />

      <Dropdown id="main-dropdown">
        <Dropdown.Toggle>{title}</Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              setTitle("ALL TOOLS");
              trackDropdownItem("ALL TOOLS");
            }}
          >
            ALL TOOLS
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setTitle("FIND LEGISLATORS");
              trackDropdownItem("FIND LEGISLATORS");
            }}
          >
            FIND LEGISLATORS
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setTitle("TIMELINES");
              trackDropdownItem("TIMELINES");
            }}
          >
            TIMELINES
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setTitle("WEBINARS");
              trackDropdownItem("WEBINARS");
            }}
          >
            WEBINARS
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setTitle("TOOLS BY REGION");
              trackDropdownItem("TOOLS BY REGION");
            }}
          >
            TOOLS BY REGION
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setTitle("SETTING THE STAGE");
              trackDropdownItem("SETTING THE STAGE");
            }}
          >
            SETTING THE STAGE
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setTitle("COALITION BUILDING");
              trackDropdownItem("COALITION BUILDING");
            }}
          >
            COALITION BUILDING
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setTitle("DEVELOPING AN ASK");
              trackDropdownItem("DEVELOPING AN ASK");
            }}
          >
            DEVELOPING AN ASK
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setTitle("RELATIONSHIP BUILDING");
              trackDropdownItem("RELATIONSHIP BUILDING");
            }}
          >
            RELATIONSHIP BUILDING
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setTitle("LEGISLATIVE/BUDGET PROCESS");
              trackDropdownItem("LEGISLATIVE/BUDGET PROCESS");
            }}
          >
            LEGISLATIVE/BUDGET PROCESS
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setTitle("IMPLEMENTATION");
              trackDropdownItem("IMPLEMENTATION");
            }}
          >
            IMPLEMENTATION
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setTitle("SPANISH TOOLS");
              trackDropdownItem("SPANISH TOOLS");
            }}
          >
            SPANISH TOOLS
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setTitle("CHINESE TOOLS");
              trackDropdownItem("CHINESE TOOLS");
            }}
          >
            CHINESE TOOLS
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <HelpPopup show={showHelpPopup} close={closeHelpPopup} />

      {title === "ALL TOOLS" && records.length > 0 ? (
        <AllTools records={records} sortInfo={sortInfo.allTools} />
      ) : null}
      {title === "FIND LEGISLATORS" ? (
        <FindLegislators
          records={records}
          sortInfo={sortInfo.findLegislators}
        />
      ) : null}
      {title === "TIMELINES" ? (
        <Timelines records={records} sortInfo={sortInfo.timelines} />
      ) : null}
      {title === "WEBINARS" ? (
        <Webinars records={records} sortInfo={sortInfo.webinars} />
      ) : null}
      {title === "TOOLS BY REGION" ? (
        <ToolsByRegion records={records} sortInfo={sortInfo.toolsByRegion} />
      ) : null}
      {title === "SETTING THE STAGE" ? (
        <SettingTheStage
          records={records}
          sortInfo={sortInfo.settingTheStage}
        />
      ) : null}
      {title === "COALITION BUILDING" ? (
        <CoalitionBuilding
          records={records}
          sortInfo={sortInfo.coalitionBuilding}
        />
      ) : null}
      {title === "DEVELOPING AN ASK" ? (
        <DevelopingAnAsk
          records={records}
          sortInfo={sortInfo.developingAnAsk}
        />
      ) : null}
      {title === "RELATIONSHIP BUILDING" ? (
        <RelBuilding
          records={records}
          sortInfo={sortInfo.relaltionshipBuilding}
        />
      ) : null}
      {title === "LEGISLATIVE/BUDGET PROCESS" ? (
        <LegBudProcess
          records={records}
          sortInfo={sortInfo.legislativeBudgetProcess}
        />
      ) : null}
      {title === "IMPLEMENTATION" ? (
        <Implementation records={records} sortInfo={sortInfo.implementation} />
      ) : null}
      {title === "SPANISH TOOLS" ? (
        <SpanishTools records={records} sortInfo={sortInfo.spanishTools} />
      ) : null}
      {title === "CHINESE TOOLS" ? (
        <ChineseTools
          records={records}
          sortInfo={
            sortInfo.chineseTools.length > 0 ? sortInfo.chineseTools : null
          }
        />
      ) : null}
    </div>
  );
}

export default MyTools;
