import React from "react";

import "../styles/Loading.css";
import { Spinner } from "react-bootstrap";

function Loading() {
  return (
    <div className="loading-container">
      <Spinner animation="border" />
      <h1>LOADING...</h1>
    </div>
  );
}

export default Loading;
